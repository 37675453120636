.suscribe {
  position: relative;
  background-image: url("../../assets/img/background/FONDO_CELESTE.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5rem 0;
  overflow: hidden;
  text-align: -webkit-center;
  padding-top: 160px;

  .content {
    color: var(--principal-text-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
    .text {
      width: 100%;
      text-align: unset;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 3rem;
      h2 {
        text-transform: uppercase;
        margin-bottom: 0.8rem;
        font-family: "Brothers";
        font-weight: 700;
        font-style: normal;
        font-size: 2.5rem;
        text-align: left;
      }
      p {
        width: 80%;
        font-family: "Archer";
        font-weight: 500;
        font-style: normal;
        font-size: 1.125rem;
      }
    }
    form {
      width: 100%;
      max-width: 85%;
      margin: 0 auto;
      .group-input {
        margin-bottom: 1rem;
        input {
          color: white;
          outline: 0;
          border-width: 0 0 1px;
          border-color: var(--principal-text-color);
          background: transparent;
          width: 100%;
          height: 35px;
          padding: 0 0.25rem;
          font-family: "Archer";
          &::placeholder {
            color: var(--principal-text-color);
            opacity: 1;
            font-family: "Archer";
            font-weight: 500;
            font-style: italic;
          }
        }
      }
      .group-checkbox {
        margin-top: 2rem;
        margin-bottom: 3rem;
        display: flex;
        justify-content: left;
        input {
          margin-right: 0.625rem;
        }

        input[type="checkbox"]:after {
          content: "";
          border-bottom: red;
          border-top: red;
          display: block;
          opacity: 1;
        }

        p {
          font-family: "Archer";
          font-weight: 400;
          font-style: normal;
          font-size: 0.875rem;

          a {
            color: var(--principal-text-color);
            font-weight: 700;
            &:link {
              text-decoration: none;
            }
            &:visited {
              text-decoration: none;
            }
            &:hover {
              text-decoration: none;
            }
            &:active {
              text-decoration: none;
            }
          }
        }
      }

      button {
        padding: 0.625rem 1.25rem;
        font-family: "Archer";
        font-weight: 700;
        font-style: normal;
        border: none;
        border-radius: 0.313rem;
        color: #00809f;
        background-color: white;
      }
    }
  }

  &::after {
    content: "";
    background-image: url("../../assets/img/deco/puntas.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 8.75rem;
    height: 10rem;
    position: absolute;
    top: -0.313rem;
    left: 0;
    transform: rotate(180deg);
  }
}

@media (min-width: 450px) {
  .suscribe {
    .content {
      display: flex;
      .text,
      form {
        width: 50%;
      }

      .text {
        text-align: left;
        p {
          width: 50%;
        }
      }

      form {
        .group-checkbox-group-submit {
          display: flex;
          justify-content: space-between;
          .group-checkbox {
            margin-top: 0;
            margin-bottom: 0;
            align-items: center;
          }
        }
      }
    }

    &::after {
      width: 15.625rem;
      height: 16.875rem;
      top: 0;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .suscribe {
    .content {
      max-width: 960px;
      margin: 0 auto;
      flex-direction: row;
      .text {
        h2 {
          text-align: center;
          max-height: 65%;
          width: 100%;
        }
        p {
          max-width: 75%;
          width: 100%;
        }
      }
    }
  }
}
