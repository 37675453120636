.culture {
  position: relative;
  background-image: url("../../assets/img/background/FONDO_MORADO.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 90vh;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;

  h2 {
    font-family: "Rumble";
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &::after {
      content: "";
      width: 30px;
      height: 8px;
      background-color: red;
      margin: 1rem 0;
    }
  }

  img {
    width: 100%;
    max-width: 480px;
  }

  .text {
    display: inline;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 25px 0 25px;
    gap: 0.5rem;
    min-width: 19%;

    .title-culture {
      font-family: "Archer";
      font-weight: 700;
      font-style: normal;
      font-size: 0.8rem;
      color: white;
      text-decoration: none;
    }

    .text-culture {
      font-family: "Archer";
      font-weight: 400;
      font-style: normal;
      font-size: 0.8rem;
      color: white;
      text-decoration: none;
    }

    .button-culture {
      background-color: transparent;
      border-radius: 10px;
      border: 1px solid #ffff;
      margin: 0.5rem 0;
      list-style: none;
      width: 40%;
      height: 15%;
      font-family: "Archer";
      font-weight: 400;
      font-style: normal;
      font-size: 0.8rem;
      color: white;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .cultureSwiper {
    width: 100%;
    height: 100%;

    &.swiper-container {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 40px;

      .swiper-button-next {
        background-image: url(../../assets/img/pCulture/right.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        cursor: pointer;

        &::after {
          display: none;
        }
      }

      .swiper-button-prev {
        background-image: url(../../assets/img/pCulture/left.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        cursor: pointer;

        &::after {
          display: none;
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        top: 95%;
      }

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background: var(--principal-text-color);
      }

      .swiper-pagination-bullet-active {
        background: var(--primary-color);
      }
    }

    .swiper-wrapper {
      .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 300px;
        height: 100%;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        img {
          display: block;
          width: 100%;
          max-width: 405px;
          margin: auto;
        }

        &:nth-child(odd) {
          transform: rotate(1deg);
        }

        &:nth-child(even) {
          transform: rotate(-1deg);
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .culture {
    display: flex;
    flex-direction: unset;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    height: auto;
    padding: 25px 0 2rem 25px;

    h2 {
      font-size: 3rem;
    }

    .text {
      margin: 0 25px 0 0;

      .title-culture {
        font-size: 1.5rem;
      }

      .text-culture {
        font-size: 1.2rem;
      }

      .button-culture {
        border-radius: 3px;
        width: 25%;
        font-size: 0.9rem;
      }
    }
  }
}
