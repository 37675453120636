.section-beers {
  position: relative;
  padding: 140px 0 80px;
  background-image: url("../../assets/img/background/bg-leyendas-100.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  h2 {
    font-family: "Rumble", fantasy;
    color: white;
    font-weight: 400;
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    span {
      font-family: "Brothers", serif;
      font-size: 2.5rem;
      color: var(--primary-color);
      background-image: url("../../assets/img/deco/bg-descubre.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      padding: 0 2rem;
    }
  }

  .beers-swiper {
    width: 100%;
    height: 100%;

    .swiper-button-prev {
      background-image: url(../../assets/img/pCulture/left.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      cursor: pointer;

      &::after {
        display: none;
      }
    }

    .swiper-button-next {
      background-image: url(../../assets/img/pCulture/right.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      cursor: pointer;

      &::after {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: white;
    }

    .swiper-pagination-bullet-active {
      background: white;
    }
    .swiper-button-prev {
      background-image: url(../../assets/img/pCulture/left.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      cursor: pointer;

      &::after {
        display: none;
      }
    }

    .swiper-button-next {
      background-image: url(../../assets/img/pCulture/right.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      cursor: pointer;

      &::after {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: white;
    }

    .swiper-pagination-bullet-active {
      background: white;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: auto;
      height: 400px;
      object-fit: contain;
    }

    .card-leyenda {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 3.5rem;

      .tag {
        background-image: url("../../assets/img/deco/bg-tag.png");
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        font-family: "Brothers";
        font-weight: 700;
        text-transform: uppercase;
        color: white;
        padding: 0.5rem;

        &::before {
          content: "·";
          margin-right: 5px;
        }

        &::after {
          content: "·";
          margin-left: 5px;
        }
      }

      .name {
        font-family: "Rumble", fantasy;
        color: white;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      .info {
        color: white;
        font-family: "Brothers";
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;

        .cc1 {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;

          span.cc2 {
            font-size: 0.5rem;
          }

          &::after {
            content: "";
            margin: 0 0.5rem;
            background-color: white;
            width: 2px;
            height: 15px;
          }
        }

        .gl {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          font-weight: 400;

          span {
            font-size: 0.8rem;
          }
        }
      }

      a {
        margin-top: 1rem;
        text-decoration: none;
        background-color: white;
        color: var(--secondary-text-color);
        padding: 0.4rem 1rem;
        border-radius: 5px;
        font-family: "Archer";
        font-weight: 700;
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

@media (min-width: 768px) {
  .section-beers {
    .beers-swiper {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }

      .card-leyenda {
        .tag {
          padding: 0.8rem 1rem;
          font-size: 1.2rem;
        }

        .name {
          font-size: 1.8rem;
        }
      }
    }
  }
}
