.history {
  padding: 2rem 0;
  background-image: url("../../assets/img/background/FONDO__CREMA_V1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-top: 85px;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-family: "Rumble", serif;
      font-weight: 400;
      font-size: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #221632;
    }

    img {
      max-width: 450px;
      width: 70%;
    }
  }

  .container-desktop {
    display: none;
  }

  .container-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .article-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      margin: auto;
      font-family: "Archer";

      &.article-mobile1 {
        flex-direction: row;
        gap: 1rem;

        img {
          width: 50%;
          max-width: 300px;
        }

        p {
          color: #221632;
          font-size: 1.2rem;
          span {
            font-weight: 700;
          }
        }
      }

      &.article-mobile2 {
        flex-direction: column;
        gap: 1rem;

        img {
          width: 100%;
        }

        p {
          color: #221632;
          font-size: 1.2rem;
          margin: auto;
          padding-left: 2rem;
          width: 80%;
        }
      }
    }

    .beers {
      width: 80%;
      margin: 1rem auto 2rem;
    }
  }
}

@media (min-width: 768px) {
  .history {
    padding: 4rem 0;
    padding-top: 130px;

    h2 {
      font-size: 2.5rem;
    }

    .container-mobile {
      display: none;
    }

    .container-desktop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      width: 85%;
    }

    .article-desk.article-desk1 {
      position: relative;
      width: 100%;
      padding-bottom: 1rem;
      border-bottom: 2px solid black;

      img {
        width: 100%;
        height: auto;
      }

      p {
        position: absolute;
        top: 0;
        right: 0;
        width: 36%;
        font-family: "Archer";
        font-weight: 400;
        color: #221632;
        font-size: 1.5rem;

        span {
          font-weight: 700;
        }
      }
    }

    .article-desk.article-desk2 {
      position: relative;
      width: 100%;
      padding: 1rem 0;
      border-bottom: 2px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      img.beers {
        width: 70%;
        height: auto;
      }

      .right {
        width: 30%;

        img {
          width: 100%;
        }

        p {
          width: 80%;
          margin-left: auto;
          font-family: "Archer";
          font-weight: 400;
          color: #221632;
          font-size: 1.5rem;

          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}
