@font-face {
  font-family: "Archer";
  src: url("../assets/fonts/Archer-Book-Pro.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archer";
  src: url("../assets/fonts/Archer-Bold-Pro.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archer";
  src: url("../assets/fonts/Archer-Medium-Pro.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archer";
  src: url("../assets/fonts/Archer-MediumItal-Pro.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Archer";
  src: url("../assets/fonts/Archer-Light-Pro.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archer";
  src: url("../assets/fonts/Archer-LightItal-Pro.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Brothers";
  src: url("../assets/fonts/Brothers\ Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rumble";
  src: url("../assets/fonts/Rumble\ Brave.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
