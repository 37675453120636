.hero {
  .hero-swiper {
    position: relative;
    width: 100%;
    height: 100%;

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-button-prev {
      background-image: url(../../assets/img/pCulture/left.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      cursor: pointer;

      &::after {
        display: none;
      }
    }

    .swiper-button-next {
      background-image: url(../../assets/img/pCulture/right.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      cursor: pointer;

      &::after {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: white;
    }

    .swiper-pagination-bullet-active {
      background: white;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .hero-card {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 80%;
          max-width: 400px;
          height: auto;
        }
      }

      &:first-child .hero-card {
        background-image: url("../../assets/img/background/bg-age-verify.jpg");
      }

      &:nth-child(2) .hero-card {
        background-image: url("../../assets/img/background/FONDO_ROJO_V4.jpg");
      }

      &:nth-child(3) .hero-card {
        background-image: url("../../assets/img/background/banner-3.png");
      }
    }
  }
}
