.newsdetail {
  position: relative;
  background-image: url("../../assets/img/background/FONDO__CREMA_V1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 1.5rem;

  &::before {
    content: "";
    width: 100%;
    height: 80px;
    position: absolute;
    top: -80px;
    box-shadow: 35px 0px 120px black;
  }

  h1 {
    font-family: "Rumble", serif;
    font-weight: 400;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;

    span {
      font-size: 1.5em;
      color: white;
      background-image: url("../../assets/img/deco/bg-text.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      padding: 1rem 2rem 1.5rem;
    }
  }

  h1.title {
    font-family: "Archer";
    font-weight: 800;
    width: 80%;
    margin: auto;
  }

  p {
    width: 80%;
    margin: auto;
    font-family: "Archer";
    font-weight: 500;
    font-size: 0.9rem;
  }

  img.img-banner {
    width: 80%;
    max-width: 380px;
    height: 255px;
    object-fit: cover;
    object-position: center;
  }

  img.img-footer {
    width: 100%;
    max-width: 1200px;
    height: 255px;
    object-fit: contain;
  }
}

@media (min-width: 768px) {
  .newsdetail {
    p {
      width: 60%;
    }
  }
}
