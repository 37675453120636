.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  z-index: 5;
  transition: all 250ms ease-in-out;
  &.bg-pink {
    background-color: #d70046;
  }

  .logo {
    img {
      width: 100px;
    }
  }

  .btn-menu {
    border: 0;
    background-color: transparent;
    width: 35px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    cursor: pointer;
    z-index: 5;
    &.open {
      span {
        &:first-child {
          transform: translateY(-50%) rotate(45deg);
          top: 50%;
        }

        &:nth-child(2) {
          left: -30px;
          opacity: 0;
        }

        &:last-child {
          transform: translateY(-50%) rotate(-45deg);
          top: 50%;
        }
      }
    }

    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 5px;
      background-color: white;
      transition: all 200ms ease-in-out;
      left: 0;
      &:first-child {
        top: 0;
      }

      &:last-child {
        top: 100%;
        transform: translateY(-5px);
      }
    }
  }

  .btn-buy {
    display: none;
  }

  .menu {
    position: absolute;
    z-index: 3;
    background-color: var(--secondary-color);
    height: 100vh;
    width: 65%;
    right: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 250ms ease-in-out;
    &.open {
      opacity: 1;
      pointer-events: all;
      nav {
        opacity: 1;
        transition-delay: 125ms;
      }

      .rrss {
        opacity: 1;
        transition-delay: 125ms;
      }
    }

    nav {
      opacity: 0;
      padding-top: 75px;
      transition: all 250ms ease-in-out;
      & > ul {
        width: 100%;
        padding: 1rem;
        li {
          margin-bottom: 12%;
          text-align: center;
          &:last-child {
            margin-bottom: 0;
          }

          ul {
            &.subnav {
              margin-top: 0.5rem;
              padding: unset;
              li {
                margin-bottom: 0;
                margin-bottom: 0.5rem;
                a {
                  font-size: 0.725rem;
                }
              }
            }
          }

          a {
            font-size: 1.2rem;
            font-family: "Brothers";
            letter-spacing: 1px;
            position: relative;
            text-transform: uppercase;
            text-decoration: none;
            transition: all 250ms ease-in-out;
            color: var(--principal-text-color);
            &.active {
              &::after {
                opacity: 1;
              }
            }

            &::after {
              opacity: 0;
              content: "";
              position: absolute;
              right: -1rem;
              top: 50%;
              transform: translateY(-50%);
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background-color: white;
              transition: all 250ms ease-in-out;
            }
          }
        }
      }
    }

    .rrss {
      opacity: 0;
      padding: 1rem;
      transition: all 250ms ease-in-out;
      display: flex;
      width: 100%;
      gap: 1.5rem;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      img {
        width: 30px;
        height: auto;
      }
    }
  }

  .menu-desktop {
    position: absolute;
    z-index: 3;
    background-color: var(--secondary-color);
    height: 100vh;
    width: 25%;
    right: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 250ms ease-in-out;
    display: none;
    &.open {
      opacity: 1;
      pointer-events: all;
      nav {
        opacity: 1;
        transition-delay: 125ms;
      }

      .rrss {
        opacity: 1;
        transition-delay: 125ms;
      }
    }

    nav {
      opacity: 0;
      padding-top: 75px;
      transition: all 250ms ease-in-out;
      & > ul {
        width: 100%;
        padding: 1rem;
        li {
          margin-bottom: 12%;
          text-align: center;
          &:last-child {
            margin-bottom: 0;
          }

          ul {
            &.subnav {
              margin-top: 0.5rem;
              padding: unset;
              li {
                margin-bottom: 0;
                margin-bottom: 0.5rem;
                a {
                  font-size: 0.725rem;
                }
              }
            }
          }

          a {
            font-size: 1.2rem;
            font-family: "Brothers";
            letter-spacing: 1px;
            position: relative;
            text-transform: uppercase;
            text-decoration: none;
            transition: all 250ms ease-in-out;
            color: var(--principal-text-color);
            &.active {
              &::after {
                opacity: 1;
              }
            }

            &::after {
              opacity: 0;
              content: "";
              position: absolute;
              right: -1rem;
              top: 50%;
              transform: translateY(-50%);
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background-color: white;
              transition: all 250ms ease-in-out;
            }
          }
        }
      }
    }

    .rrss {
      opacity: 0;
      padding: 1rem;
      transition: all 250ms ease-in-out;
      display: flex;
      width: 100%;
      gap: 1.5rem;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      img {
        width: 30px;
        height: auto;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .header {
    .menu {
      width: 50%;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .header {
    margin: 0 auto;
    width: 100%;
    position: fixed;
    flex-direction: row-reverse;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 2rem;

    // .btn-menu {
    //   display: none;
    // }

    .logo {
      display: none;
    }

    .menu {
      display: flex;
      opacity: 1;
      position: relative;
      flex-direction: row-reverse;
      justify-content: space-between;
      height: auto;
      width: 100%;
      background: unset;
      top: unset;
      left: unset;
      text-indent: unset;
      pointer-events: all;
      nav {
        opacity: 1;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        ul {
          display: flex;
          padding: 0;
          flex-direction: row;
          justify-content: space-evenly;
          li {
            margin: 0;
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
              display: none;
            }
            ul {
              &.subnav {
                display: none;
              }
            }
            a {
              font-size: 1rem;
              &:after {
                bottom: -0.825rem;
                top: unset;
                left: 50%;
                right: unset;
                width: 15px;
                border-radius: 3px;
                transform: translateX(-50%);
              }
            }
          }
        }
      }

      .rrss {
        width: auto;
        margin: 0;
        padding: 0;
        opacity: 1;
        flex-direction: row;
        gap: 0.725rem;
        li {
          a {
            svg {
              width: 20px;
            }
          }
        }
      }
    }

    .menu-desktop {
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header {
    .btn-buy {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-family: "Brothers";
      border: unset;
      background-color: var(--principal-text-color);
      color: black;
      border-radius: 5px;
      padding: 0.5rem 0;
      width: 120px;
      margin-right: 2rem;
      font-size: 0.825rem;
      text-decoration: none;
    }
    .menu {
      nav {
        ul {
          justify-content: center;
          gap: 10%;
          li {
            a {
              &:hover {
                &::after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .rrss {
        gap: 1rem;
        li {
          a {
            svg {
              width: 25px;
            }
          }
        }
      }
    }
  }
}

// @media only screen and (min-width: 1400px) {
//   .header {
//     max-width: 1366px;
//   }
// }
