.blue {
  position: relative;
  background-image: url("../../assets/img/background/FONDO_CELESTE.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh;
  padding: 5rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;

  img {
    width: 80%;
    max-width: 480px;
  }

  &::before {
    content: "";
    background-image: url("../../assets/img/deco/p1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 180px;
    height: 220px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &::after {
    content: "";
    background-image: url("../../assets/img/deco/puntas.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 250px;
    height: 270px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.red {
  position: relative;
  background-image: url("../../assets/img/background/FONDO_ROJO_V4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 80%;
    max-width: 480px;
  }

  &::before {
    content: "";
    background-image: url("../../assets/img/deco/puntas-red.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 15%;
    height: 100%;
    position: absolute;
    left: 0;
  }

  &::after {
    content: "";
    background-image: url("../../assets/img/deco/puntas-red.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 15%;
    height: 100%;
    transform: scaleX(-1);
    position: absolute;
    right: 0;
  }
}

@media (min-width: 768px) {
  .blue {
    &::before {
      width: 450px;
      height: 500px;
    }

    &::after {
      width: 550px;
      height: 600px;
    }
  }
}
