.suscribe-modal {
  background-color: rgba($color: #000000, $alpha: 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;

  &.none {
    display: none;
  }

  .container-modal {
    border-radius: 10px;
    background-image: url("../../assets/img/background/FONDO_CELESTE.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    text-align: center;
    position: relative;
    color: var(--principal-text-color);
    width: 90%;
    max-width: 1000px;

    button.close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1.5rem;
      background: transparent;
      border: none;
      color: white;
      padding: 0.5rem;
    }

    .content-modal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 1rem;

      h2 {
        text-transform: uppercase;
        margin-bottom: 0.8rem;
        font-family: "Brothers";
        font-weight: 700;
        font-style: normal;
        font-size: 2.5rem;
        text-align: left;
      }

      p {
        font-family: "Archer";
        font-weight: 500;
        font-style: normal;
        font-size: 1.125rem;
      }

      form {
        margin-top: 1rem;
        width: 100%;
        .group-input {
          margin-bottom: 0.8rem;

          input {
            color: white;
            outline: 0;
            border-width: 0 0 1px;
            border-color: var(--principal-text-color);
            background: transparent;
            width: 100%;

            &::placeholder {
              color: var(--principal-text-color);
              opacity: 1;
              font-family: "Archer";
              font-weight: 500;
              font-style: italic;
            }
          }
        }

        .group-checkbox {
          margin-top: 2.2rem;
          margin-bottom: 3rem;
          display: flex;
          justify-content: left;

          input {
            margin-right: 0.625rem;
          }

          label {
            font-family: "Archer";
            font-weight: 400;
            font-style: normal;
            font-size: 0.875rem;

            a {
              color: var(--principal-text-color);
              font-weight: 700;

              &:link {
                text-decoration: none;
              }

              &:visited {
                text-decoration: none;
              }

              &:hover {
                text-decoration: none;
              }

              &:active {
                text-decoration: none;
              }
            }
          }
        }

        button {
          padding: 0.625rem 1.25rem;
          font-family: "Archer";
          font-weight: 700;
          font-style: normal;
          border: none;
          border-radius: 0.313rem;
          color: #00809f;
          background-color: white;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .suscribe-modal {
    .container-modal {
      .content-modal {
        p {
          font-size: 1.3rem;
          width: 60%;
        }

        form {
          width: 60%;
          .group-checkbox-group-submit {
            display: flex;
            justify-content: space-between;

            .group-checkbox {
              margin-top: 0;
              margin-bottom: 0;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
