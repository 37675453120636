@import "./fonts";
@import "./normalize";
@import "./variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

.validate {
  overflow: hidden;
}

.no-scroll {
  overflow: hidden;
}
