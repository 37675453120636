.contact {
  position: relative;
  background-image: url("../../assets/img/background/FONDO_MORADO_V2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 4rem 0;
  overflow: hidden;
  text-align: -webkit-center;
  position: relative;
  overflow: hidden;
  .floatLeft {
    position: absolute;
    left: -5%;
    bottom: -10%;
    height: 450px;
    display: none;
  }

  .floatRight {
    position: absolute;
    right: -5%;
    bottom: -10%;
    height: 450px;
    display: none;
  }

  .content {
    color: var(--principal-text-color);
    width: 80vw;

    h2 {
      text-transform: uppercase;
      margin-bottom: 2.5rem;
      font-family: "Brothers";
      font-weight: 700;
      font-style: normal;
      font-size: 30px;
    }

    form {
      margin-bottom: 2.5rem;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .group-input {
        display: flex;
        width: 100%;
        input {
          margin-bottom: 1.8rem;
        }
        textarea {
          margin-bottom: 1.2rem;
          min-width: 60%;
          min-height: 60px;
        }
        input,
        textarea {
          color: white;
          width: 100%;
          outline: 0;
          border-width: 0 0 1px;
          border-color: var(--principal-text-color);
          background: transparent;
          &::placeholder {
            color: var(--principal-text-color);
            opacity: 1;
            font-family: "Archer";
            font-weight: 300;
            font-style: italic;
          }
        }
      }
      button {
        font-family: "Archer";
        font-weight: 700;
        padding: 5px 30px;
        color: white;
        background-color: var(--primary-color);
        border: none;
        border-radius: 5px;
      }
    }

    .group-others-logo {
      .others {
        p {
          text-transform: uppercase;
          font-family: "Brothers";
          font-weight: 700;
          font-style: normal;
          margin-bottom: 0.5rem;
        }

        .links {
          display: flex;
          place-content: center;

          ul li {
            display: inline;
            margin-right: 0.5rem;
          }

          a {
            color: var(--principal-text-color);
            font-family: "Archer";
            font-weight: 700;
            font-style: normal;

            &:link {
              text-decoration: none;
            }
            &:visited {
              text-decoration: none;
            }
            &:hover {
              text-decoration: none;
            }
            &:active {
              text-decoration: none;
            }

            & img {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }

      .logo {
        width: 8rem;
        margin-top: 4rem;
      }
    }
  }
}

@media (max-height: 700px) {
  .contact {
    .content {
      .group-others-logo {
        .logo {
          margin-top: 2rem;
        }
      }
    }
  }
}

@media (min-width: 450px) {
  .contact {
    padding: 5rem 0;
    .content {
      width: 50vw;
      .group-others-logo {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-top: 3rem;

        .others,
        .container-logo {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 130px;
        }

        .container-logo {
          border-right: 1px solid var(--principal-text-color);
          .logo {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .contact {
    .floatLeft {
      display: flex;
    }

    .floatRight {
      display: flex;
    }
  }
}
