.card-culture {
  width: 90%;
  max-width: 300px;
  margin: auto;
  padding: 7px 7px 1rem;
  background-color: #e8e5de;
  box-shadow: 3px 6px 40px rgba(0, 0, 0, 0.6);
  margin-bottom: 1rem;

  .card-img {
    width: 100%;
    height: auto;
  }

  .card-content {
    padding: 1rem 7px 0.5rem;
    height: 235px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;

    .card-title {
      font-family: "Archer";
      font-weight: 800;
      font-size: 1.2rem;
      line-height: 24px;
      color: #2e1a46;
    }

    .card-description {
      font-family: "Archer";
      font-weight: 400;
      font-size: 1rem;
      line-height: 25px;
      color: #2e1a46;
    }
  }
}
