.news {
  position: relative;
  background-image: url("../../assets/img/background/FONDO__CREMA_V1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 2rem;
  padding: 25px 0 2rem 0;
  min-height: 90vh;

  h2 {
    min-width: 20%;
    font-family: "Rumble", serif;
    font-weight: 400;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #221632;

    span {
      font-size: 1.5em;
      color: white;
      background-image: url("../../assets/img/deco/bg-text.svg");
      background-repeat: no-repeat;
      background-position: bottom;
      padding: 2rem;
      background-size: cover;
      padding-bottom: 1.825rem;
      padding-top: 2.5rem;
    }
  }

  .PNewsSwiper {
    width: 100%;
    height: 100%;

    .swiper-button-next {
      background-image: url("../../assets/img/PNews/left1.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      cursor: pointer;
      transform: scaleX(-1);

      &::after {
        display: none;
      }
    }

    .swiper-button-prev {
      background-image: url("../../assets/img/PNews/left1.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      cursor: pointer;

      &::after {
        display: none;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 95%;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: var(--secondary-color);
    }

    .swiper-pagination-bullet-active {
      background: var(--primary-color);
    }

    .swiper-slide {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      padding-bottom: 4rem;

      img {
        display: block;
        width: 100%;
        max-width: 405px;
        margin: auto;
      }
    }
  }
}

@media (min-width: 768px) {
  .news {
    display: flex;
    flex-direction: unset;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    height: auto;
    padding: 25px 0 2rem 25px;

    h2 {
      font-size: 3rem;
      span {
        font-size: 2em;
      }
    }
  }
}
