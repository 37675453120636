.ageverify-modal {
  position: fixed;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  z-index: 100;
  background-image: url("../../assets/img/background/bg-age-verify.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;

  &.none {
    display: none;
  }

  .container-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .video {
      position: fixed;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
    }
    .overlay {
      width: 100vw;
      height: 100vh;
      position: relative;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  .container-verify {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    width: 100%;

    .verify {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .left {
        width: 115px;
        text-align: end;
        p {
          font-family: "Brothers";
          font-weight: 700;
          text-transform: uppercase;
          font-size: 2rem;

          span.outline {
            display: block;
            font-weight: 700;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 0.5px;
            -webkit-text-stroke-color: white;
            line-height: 1.3;
            letter-spacing: 2px;
          }

          span.number {
            vertical-align: bottom;
            font-size: 3.5rem;
            line-height: 1;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        button {
          cursor: pointer;
          border: 0;
          width: 100px;
          height: 60px;
          font-family: "Brothers";
          font-weight: 700;
          text-transform: uppercase;
          font-size: 2rem;
          color: white;
          background-color: transparent;
          background-image: url("../../assets/img/deco/outline-btn.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 100%;
          transition: all 400ms;

          &:hover {
            background-color: white;
            color: black;
          }
        }
      }
    }

    p.younger {
      display: none;
      text-align: center;
      font-family: "Brothers";
      text-transform: uppercase;
      font-size: 1.5rem;
      position: relative;
      z-index: 100;

      span {
        font-size: 3.5rem;
        line-height: 1;
        vertical-align: middle;
        text-align: center;
      }
    }

    img.logo {
      position: relative;
      z-index: 2;
      width: 200px;
    }
  }
}

@media (min-width: 768px) {
  .ageverify-modal {
    .container-verify {
      align-items: flex-end;
      width: 55%;
    }
  }
}
