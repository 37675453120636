.card-news {
  width: 90%;
  background-color: #e8e5de;
  margin-bottom: 2rem;

  .card-img {
    width: 100%;
  }

  .card-content {
    position: relative;
    padding: 2.5rem 0 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    width: 80%;
    height: 325px;
    margin: auto;
  }

  .card-title {
    font-family: "Archer";
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 24px;
    color: #2e1a46;
  }

  .card-description {
    font-family: "Archer";
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 25px;
    color: #2e1a46;
  }

  .card-btn {
    position: absolute;
    top: 100%;
    transform: translate(0, -50%);
    text-decoration: none;
    padding: 1rem 3rem;
    font-family: "Archer";
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 21.26px;
    color: #e8e5de;
    background-color: #d70045;
  }
}

@media (min-width: 768px) {
  .card-news {
    margin-bottom: 1rem;
  }
}
