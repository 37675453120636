.container-beerHistory {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem 1rem;
  position: relative;
  &.black {
    background-color: black;
    padding-top: 80px;
    .prev-btn-swiper,
    .next-btn-swiper {
      color: black;
      background-color: white;
    }
  }

  .bold {
    font-weight: 900;
  }

  .text-bold {
    font-weight: 900;
    font-size: 2rem;
    font-family: "Brothers";
  }

  .swiper-slide {
    height: 100%;
    opacity: 0 !important;
    transition: all 1000ms ease-in-out;
    &.swiper-slide-active {
      opacity: 1 !important;
      transition-delay: 500ms;
    }
  }

  .swiper-pagination {
    top: 20%;
    bottom: unset;
    .swiper-pagination-bullet {
      background-color: white;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background-color: var(--primary-color);
      }
    }
  }

  .card-beer {
    max-width: 320px;
    margin: 0 auto;
    padding: 1rem;
    height: 100%;
    .header-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 0;
      img {
        &.bannerName {
          margin-bottom: 1rem;
          width: 100%;
        }
      }

      h3 {
        color: black;
        margin: 0;
        width: auto;
        text-align: center;
        font-size: 3.5rem;
        margin-bottom: 0.5rem;
        font-family: "Rumble";
      }

      p {
        cursor: pointer;
        background-color: transparent;
        font-family: "Brothers";
        font-weight: 400;
        color: black;
        border: 1px solid black;
        width: auto;
        text-align: center;
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: 5px;

        span {
          font-weight: 700;
          font-size: 2.5rem;
        }
      }

      img {
        width: 100%;
      }
    }

    .body-card {
      .bannerName {
        display: none;
      }

      .content {
        &:first-child {
          display: none;
        }
      }

      p {
        color: black;
        font-family: "Archer";
        line-height: 1.4;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
        &.textMobile {
        }

        &.textDesktop {
          display: none;
        }
      }

      ul:not(.beer-info) {
        margin-top: 2rem;
        padding: 1rem 0;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        &.beer-info {
          border-top: 1px solid black;
          border-bottom: 1px solid black;
        }

        li {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          position: relative;
          list-style: circle;
          &:last-child {
            margin-bottom: 0;
          }
          .type {
            p {
              margin: 0;
              font-family: "Archer";
              position: relative;
              &:first-child {
                font-size: 1.1rem;
                font-weight: 500;
              }

              &:last-child {
                font-weight: 300;
              }
            }
          }
        }
      }

      ul {
        &.beer-info {
          li {
            .type {
              p {
                &:first-child {
                  font-weight: 900;
                }
              }
            }
          }
        }
      }

      .body-image {
        width: 100%;
      }

      .amount {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
          font-size: 1.825rem;
          font-family: "Brothers";
          font-weight: bold;

          span {
            font-family: "Archer";
            font-weight: bolder;
          }
        }
      }

      .container-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1.5rem 0;
        a,
        button {
          max-width: 40%;
          width: 100%;
          text-decoration: none;
          color: white;
          background-color: #bc8935;
          padding: 0.5rem 0;
          border-radius: 5px;
          text-align: center;
          font-family: "Archer";
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;

          i {
            font-size: 0.9rem;
          }
        }
      }
    }

    img {
      &.btn-image {
        width: 100%;
      }
    }

    &.first {
      p {
        &.description {
          border: 1px solid white !important;
        }
      }
    }

    &.second {
      padding-bottom: 0;
      max-width: 100%;
      .header-card {
        margin: 0 auto;
        p {
          font-size: 2rem;
          border: unset;

          &.description {
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .body-card {
        max-width: 320px;
        margin: 0 auto;
        margin-top: 5rem;
        padding: 0 1rem;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: -2rem;
          left: 1rem;
          width: 50px;
          height: 10px;
          border-radius: 2px;
          background-color: #bc8935;
        }

        .content {
          &:nth-child(2) {
            .description {
              display: none;
            }
          }

          &:nth-child(3) {
            display: none;
          }
        }

        h2 {
          position: relative;
          margin: 2rem 0;
          margin-bottom: 1rem;
          color: white;
          font-size: 2rem;
          font-family: "Brothers";
          font-weight: 700;
          margin-bottom: 1rem;
          line-height: 0.9;
          text-transform: uppercase;
          span {
            position: relative;
            left: -10px;
            padding: 0.5rem 1rem;
            width: min-content;
            color: black;
            display: block;
            background-image: url("../../assets/img/deco/bg-freak.png");
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        p {
          font-weight: 500;
        }

        ul {
          border: unset;
          &.beer-info {
            border: unset !important;
            list-style: disc !important;
            li {
              margin-bottom: 0.5rem !important;
              .type {
                font-size: 1rem;
                p {
                  font-size: 1rem;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }

    &.third {
      padding-bottom: 0;
      max-width: 100%;
      .body-card {
        margin-top: 5rem;
        .content {
          img {
            width: 100%;
          }
        }
      }
    }
  }

  &.black {
    .card-beer {
      .header-card {
        h3 {
          color: white;
        }

        p {
          color: white;
          border: unset;
        }

        img {
          padding-top: 50px;
        }

        .bannerName {
          padding-top: 0;
        }
      }

      .body-card {
        .content {
          h2 {
            font-size: 3rem;
            span {
              color: black;
              padding-top: 1rem;
              padding-bottom: 1.725rem;
              background-size: cover;
            }
          }

          p {
            color: white;
          }

          ul {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            border-color: white;
            padding: 0 0 2rem;
            li {
              margin-bottom: 2rem;
              &:last-child {
                margin-bottom: 0;
              }
              .type {
                p {
                  margin-bottom: 0;
                }
              }
            }
          }

          ul.beer-info {
            li {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-bottom: 0.5rem;

              .beer-extra {
                max-width: 70px;
                width: 18%;
                height: auto;
              }

              &:last-child .beer-extra {
                border-style: none;
                display: flex;
                align-self: flex-start;
              }
            }
          }
        }
      }
    }
  }

  &.yellow {
    padding-top: 80px;
    .swiper-pagination {
      top: 23%;
    }
    .card-beer {
      .header-card {
        h3 {
          color: #182944;
        }
        img {
          padding-top: 50px;
        }

        p {
          color: #192945;
          &.description {
            color: #192945;
          }
        }

        .bannerName {
          padding-top: 0;
          color: #192945;
        }
      }

      .body-card {
        &::before {
          background-color: #9d1f29;
        }

        p {
          color: #182944;
        }

        h2 {
          color: #202945;
          font-size: 3rem;
          span {
            background-image: url("../../assets/img/deco/bg-freak-black.png");
            color: #9d1e29;
            padding-top: 1rem;
            padding-bottom: 1.725rem;
            background-size: cover;
          }
        }

        .content {
          p {
            border-color: #182944;
          }

          ul {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            border-color: #182944;
            padding-bottom: 2rem;
            li {
              margin-bottom: 1rem;
              &:last-child {
                margin-bottom: 0;
              }
              .type {
                p {
                  margin-bottom: 0;
                }
              }
            }
          }

          ul.beer-info {
            li {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-bottom: 0.5rem;

              .beer-extra {
                max-width: 70px;
                width: 18%;
                height: auto;
              }

              &:last-child .beer-extra {
                border-style: none;
                display: flex;
                align-self: flex-start;
              }
            }
          }
        }

        .container-buttons {
          a,
          button {
            background-color: #9d1f29;
          }
        }
      }

      &.first {
        p {
          &.description {
            border: 1px solid black !important;
          }
        }
      }
    }
  }

  &.skyBlue {
    padding-top: 80px;
    .card-beer {
      .header-card {
        h3 {
          color: #004437;
        }
        img {
          padding-top: 50px;
        }

        p {
          color: #004437;
          &.description {
            color: #004437;
          }
        }

        .bannerName {
          padding-top: 0;
        }
      }

      .body-card {
        p {
          color: #004437;
        }
        ul {
          border-color: #182944;
        }
        .container-buttons {
          a,
          button {
            background-color: #ff9d1a;
            color: #004437;
          }
        }

        .content {
          p {
            border-color: #182944;
          }

          ul {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            border-color: #182944;
            padding: 2rem 0;
            li {
              margin-bottom: 1rem;
              &:last-child {
                margin-bottom: 0;
              }
              .type {
                p {
                  margin-bottom: 0;
                }
              }
            }
          }
          ul.beer-info {
            li {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-bottom: 0.5rem;

              .beer-extra {
                max-width: 70px;
                width: 18%;
                height: auto;
              }

              &:last-child .beer-extra {
                border-style: none;
                display: flex;
                align-self: flex-start;
              }
            }
          }
        }
      }

      &.first {
        p {
          &.description {
            border: 1px solid black !important;
          }
        }
      }
    }
  }

  &.orange {
    padding-top: 80px;
    .card-beer {
      .body-card {
        &::before {
          background-color: #81c7bd;
        }

        .container-buttons {
          a,
          button {
            background-color: #81c7bd;
            color: black;
          }
        }

        .content {
          p {
            border-color: #182944;
          }

          ul {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            border-color: #182944;
            padding: 2rem 0;
            li {
              margin-bottom: 1rem;
              &:last-child {
                margin-bottom: 0;
              }
              .type {
                p {
                  margin-bottom: 0;
                }
              }
            }
          }

          ul.beer-info {
            li {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-bottom: 0.5rem;

              .beer-extra {
                max-width: 70px;
                width: 18%;
                height: auto;
              }

              &:last-child .beer-extra {
                border-style: none;
                display: flex;
                align-self: flex-start;
              }
            }
          }
        }
      }

      .header-card {
        img {
          padding-top: 50px;
        }

        .bannerName {
          padding-top: 0;
        }
      }

      &.first {
        p {
          &.description {
            border: 1px solid black !important;
          }
        }
      }
    }
  }

  &.orange,
  &.skyBlue {
    .card-beer {
      &.second {
        .body-card {
          .content {
            &:nth-child(2) {
              h2 {
                font-size: 3rem;
                span {
                  background-image: url("../../assets/img/deco/bg-freak-green.png");
                  padding-top: 1rem;
                  padding-bottom: 1.725rem;
                  background-size: cover;
                }
              }
            }
          }
        }
      }
    }
  }

  &.skyBlue {
    .card-beer {
      &.second {
        .body-card {
          .content {
            &:nth-child(2) {
              h2 {
                color: #004437;
                span {
                  color: #ff9d1a;
                }
              }
            }
          }
        }
      }
    }
  }

  &.orange {
    .card-beer {
      &.second {
        .body-card {
          .content {
            &:nth-child(2) {
              h2 {
                color: #101820;
                span {
                  color: #80c7bc;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 0.5rem;
  button {
    background-color: white;
    border-radius: 50%;
    padding: 0.25rem;
    font-size: 1rem;
    border: unset;
    height: 26px;
    width: 26px;
    background: unset;
    border: 1px solid black;
    font-family: "Archer";
  }
}

.modal-body {
  iframe {
    height: 230px;
    width: 400px;
  }
}

.next-btn-swiper {
  position: absolute;
  right: 1rem;
  bottom: unset;
  top: 20%;
  z-index: 5;
  background-color: var(--secondary-color);
  color: white;
  border: unset;
  width: 20px;
  height: 20px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
}

.prev-btn-swiper {
  position: absolute;
  left: 1rem;
  bottom: unset;
  top: 20%;
  z-index: 5;
  background-color: var(--secondary-color);
  color: white;
  border: unset;
  width: 20px;
  height: 20px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  .next-btn-swiper {
    bottom: 2rem;
    top: unset;
    right: 20%;
  }
  .prev-btn-swiper {
    bottom: 2rem;
    top: unset;
    left: 20%;
  }
  .container-beerHistory {
    .swiper-button-prev,
    .swiper-button-next {
      top: 97%;
      color: white;
      &::after {
        background-color: var(--secondary-color);
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 12px;
      }
    }

    .swiper-pagination {
      top: 97%;
      .swiper-pagination-bullet {
        background-color: white;
        opacity: 1;
        &.swiper-pagination-bullet-active {
          background-color: var(--primary-color);
        }
      }
    }

    .card-beer {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-evenly;
      gap: 1rem;
      max-width: 990px;
      &.second {
        flex-direction: column;
        justify-content: space-between;
        .header-card {
          display: none;
        }

        .body-card {
          max-width: 100%;
          display: flex;
          flex-direction: row;
          margin-top: 0;
          justify-content: space-around;

          .content {
            max-width: 40%;
            width: 100%;
            &:first-child {
              ul {
                &.beer-info {
                  list-style: none !important;
                  border-top: 2px solid #202943 !important;
                  border-bottom: 2px solid #202943 !important;

                  li {
                    .type {
                      p {
                        &:first-child {
                          font-weight: bold;
                        }
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(2) {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              justify-content: center;
              .description {
                display: block;
                font-size: 2.5rem;
                text-transform: uppercase;
                span {
                  font-size: 4rem;
                  display: block;
                }
              }

              .character {
                font-family: "Brothers";
                font-weight: 400;
                line-height: 1;

                span {
                  font-weight: 700;
                }
              }

              h2 {
                font-size: 3rem;
                color: #004437;
                span {
                  color: #ff9d1a;
                }
              }

              ul {
                li {
                  margin-bottom: 1rem !important;
                }
              }
            }

            &:nth-child(3) {
              img {
                width: 100%;
              }
            }
          }
        }

        .btn-image {
          display: none;
        }
      }

      &.third {
        display: flex;
        flex-direction: row;
        .header-card {
          display: none;
        }

        .body-card {
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          margin-top: 0;
          .content {
            &:first-child {
              max-width: 30%;
            }

            &:nth-child(2) {
              max-width: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .btn-image {
          display: none;
        }
      }

      .header-card {
        max-width: 50%;
        width: 100%;
        .bannerName {
          display: none;
        }
      }

      .body-card {
        max-width: 30%;
        width: 100%;
        .content {
          &:first-child {
            display: block;
            ul {
              border-top: 1px solid black;
              border-bottom: 1px solid black;
            }
          }

          &:nth-child(2) {
            position: relative;
            & > p:not(.description) {
              margin-top: 3.5rem;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: -1.5rem;
                left: 0;
                width: 55px;
                height: 5px;
                border-radius: 2px;
                background-color: #bc8935;
              }
            }

            .description {
              font-size: 2rem;
              line-height: 1.1;
              span {
                font-size: 3rem;
              }
            }
          }

          p {
            &.textMobile {
              display: none;
            }

            &.textDesktop {
              display: flex;
            }
          }
        }

        .bannerName {
          display: flex;
          width: 100%;
          margin-bottom: 3rem;
        }
      }

      .btn-image {
        max-width: 200px;
        width: 100%;
      }
    }

    &.yellow {
      padding-top: 100px;
      .card-beer {
        &.second {
          .body-card {
            flex-direction: row-reverse;
          }
        }

        &.third {
          .body-card {
            flex-direction: row-reverse;
          }
        }
      }

      .swiper-pagination {
        bottom: 0;
        top: unset;
      }
    }

    &.yellow,
    &.orange {
      padding-top: 100px;
      .card-beer {
        flex-direction: row;
        &.second {
          .body-card {
            flex-direction: row-reverse;
          }
        }
      }
    }

    &.orange {
      .card-beer {
        &.second {
          .body-card {
            .content {
              &:nth-child(2) {
                .description {
                }

                h2 {
                  color: black;
                  span {
                    color: #80c7bc;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.black {
      padding-top: 100px;
      ul.beer-info {
        border-color: white !important;
      }
      .card-beer {
        &.second {
          .body-card {
            .content {
              &:first-child {
                ul.beer-info {
                  border-top: 2px solid white !important;
                  border-bottom: 2px solid white !important;
                }
              }
              &:nth-child(2) {
                h2 {
                  color: white;
                  span {
                    color: black;
                    padding-top: 1rem;
                    padding-bottom: 1.725rem;
                    background-size: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.yellow {
      .card-beer {
        &.second {
          .body-card {
            .content {
              &:nth-child(2) {
                h2 {
                  color: #202945;
                  span {
                    background-image: url("../../assets/img/deco/bg-freak-black.png");
                    color: #9d1e29;
                    padding-top: 1rem;
                    padding-bottom: 1.725rem;
                    background-size: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.orange,
    &.skyBlue {
      .card-beer {
        &.second {
          .body-card {
            .content {
              &:nth-child(2) {
                h2 {
                  span {
                    background-image: url("../../assets/img/deco/bg-freak-green.png");
                    padding-top: 1rem;
                    padding-bottom: 1.725rem;
                    background-size: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-body {
    iframe {
      height: 400px;
      width: 800px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .container-beerHistory {
    .card-beer {
      &.second {
        .body-card {
          .content {
            &:nth-child(1) {
              max-width: 30%;
              width: 100%;
            }

            &:nth-child(2) {
              max-width: 30%;
              width: 100%;
            }

            &:nth-child(3) {
              display: flex;
              align-items: center;
              justify-content: center;
              max-width: 20%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .container-beerHistory {
    .card-beer {
      max-width: 1366px;
    }
  }
}
