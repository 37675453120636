.footer {
  padding: 1rem 2rem;
  background-color: var(--primary-color);

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;

    li {
      small {
        font-family: "Archer";
        font-weight: 400;
        font-size: 0.8rem;
        color: white;
      }

      a {
        font-family: "Archer";
        font-weight: 700;
        font-size: 0.8rem;
        color: white;
        text-decoration: none;
      }

      &:first-child {
        display: flex;
        order: 1;
      }

      &:nth-child(2) {
        &::after {
          content: "|";
          padding: 0 0.5rem;
          color: white;
          font-weight: 400;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .footer {
    ul {
      li {
        small {
        }

        a {
        }

        &:first-child {
          display: flex;
          order: 0;

          &::after {
            content: "|";
            padding: 0 0.5rem;
            color: white;
          }
        }
      }
    }
  }
}
